

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { riskGroups } from '@/constant/constants';
import apiBackend from '@/service/api-insurance';
import PopupDialogModal from '@/components/form/popup-dialog-modal.vue';
import { differenceBetweenDates, onCurrencyFocus, recursiveFillData } from '@/helper/functions';
import CalculatedRisksTooltip from '@/views/components/assembly-insurance/calculated-risks-tooltip.vue';
import CranesCoInsuranceTooltip from '@/views/components/assembly-insurance/cranes-co-insurance-tooltip.vue';
import FloatingPropertyCoInsuranceTooltip
  from '@/views/components/assembly-insurance/floating-property-co-insurance.vue';
import CoverageLimitationsTooltip from '@/views/components/assembly-insurance/coverage-limitations-tooltip.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import priceStatusIcon from '@/components/form/price-status-icon.vue';
import watchers from '@/watchers/change-data-for-price-request/assembly-insurance/calc-tab';

type calculatedRisksItemType = {
  kurzbeschreibung: string;
  risikogruppe: object|string;
  anteiligeVersicherungssumme: number|null;
  montagedauer: number;
  kalkulatorischeSelbstbeteiligungMontage: number|null;
  dauerDesProbebetriebes: number;
  kalkulatorischeSelbstbeteiligungProbebetrieb: number|null;
}

const calculatedRisksItem: calculatedRisksItemType = {
  kurzbeschreibung: '',
  risikogruppe: '',
  anteiligeVersicherungssumme: 0,
  montagedauer: 0,
  kalkulatorischeSelbstbeteiligungMontage: 0,
  dauerDesProbebetriebes: 0,
  kalkulatorischeSelbstbeteiligungProbebetrieb: 0,
};

const dialogText = 'Achtung! Die Werte wurden bereits in den Bereich “Mitversicherung auf erstes Risiko” übertragen. <br> Möchten Sie den neuen Wert wirklich übertragen?';

export default Vue.extend({
  name: 'assembly-insurance-tarif',
  mixins: [dataChangeMixin],
  components: {
    priceStatusIcon,
    CoverageLimitationsTooltip,
    FloatingPropertyCoInsuranceTooltip,
    CranesCoInsuranceTooltip,
    PopupDialogModal,
    CalculatedRisksTooltip,
    vSelect,
  },
  data() {
    return {
      name: 'assembly-insurance-tarif',
      key: 'ASSEMBLY_INSURANCE',
      gefahrengrad: null,
      oldGefahrengrad: null,
      openDialog: false,
      backUrl: 'assembly-insurance',
      dialogText,
      montageversicherung: {
        montagemabnahme: '',
        artDerIndustriemontage: '',
        artDerEnergieMontage: '',
        dauerDerMontageGesamt: {
          disabledValue: 0 as number | null,
          enabledValue: '',
        },
        dauerDerMontageGesamtChanged: false,
        dauerDesProbebetriebsGesamt: {
          disabledValue: 0 as number | null,
          enabledValue: '',
        },
        dauerDesProbebetriebsGesamtChanged: false,
      },
      versicherungssummeGesamt: {
        neuwertKontraktpreisInklFrachtMontageZollkostenGewinn: 0,
        zzglLieferungenUndLeistungenDesBestellers: 0,
        gesamtversicherungssumme: 0,
        bereitsMontierteLeistung: 0,
        umsatzsteuerInDerVSEnthalten: true,
      },
      ErlauterungZurMontagemabnahme: '',
      kalkulatorischeRisikenItems: [
        {
          name: 'Objekt 1',
          ...calculatedRisksItem,
        },
        {
          name: 'Objekt 2',
          ...calculatedRisksItem,
        },
        {
          name: 'Objekt 3',
          ...calculatedRisksItem,
        },
        {
          name: 'Objekt 4',
          ...calculatedRisksItem,
        },
        {
          name: 'Objekt 5',
          ...calculatedRisksItem,
        },
        {
          name: 'Objekt 6',
          ...calculatedRisksItem,
        },
        {
          name: 'Objekt 7',
          ...calculatedRisksItem,
        },
        {
          name: 'Objekt 8',
          ...calculatedRisksItem,
        },
      ],
      VertraglicheSelbstbeteiligungen: {
        variant1: {
          variant: 'variant1',
          color: 'bg-green',
          SelbstbeteiligungMontage: {
            SBBetrag: 0,
            SBPercentage: 0,
            SBMind: 0,
            SBMax: 0,
          },
          SBMontageBemerkungMontage: '',
          SelbstbeteiligungProbebetrieb: {
            SBBetrag: 0,
            SBPercentage: 0,
            SBMind: 0,
            SBMax: 0,
          },
          SBMontageBemerkungProbebetrieb: '',
          SelbstbeteiligungDiebstahl: {
            SBPercentage: 0,
            SBMind: 0,
          },
          sentPriceRequest: false,
        },
        variant2: {
          variant: 'variant2',
          color: 'bg-blue',
          SelbstbeteiligungMontage: {
            SBBetrag: 0,
            SBPercentage: 0,
            SBMind: 0,
            SBMax: 0,
          },
          SBMontageBemerkungMontage: '',
          SelbstbeteiligungProbebetrieb: {
            SBBetrag: 0,
            SBPercentage: 0,
            SBMind: 0,
            SBMax: 0,
          },
          SBMontageBemerkungProbebetrieb: '',
          SelbstbeteiligungDiebstahl: {
            SBPercentage: 0,
            SBMind: 0,
          },
          sentPriceRequest: false,
        },
        variant3: {
          variant: 'variant3',
          color: 'bg-green-light',
          SelbstbeteiligungMontage: {
            SBBetrag: 0,
            SBPercentage: 0,
            SBMind: 0,
            SBMax: 0,
          },
          SBMontageBemerkungMontage: '',
          SelbstbeteiligungProbebetrieb: {
            SBBetrag: 0,
            SBPercentage: 0,
            SBMind: 0,
            SBMax: 0,
          },
          SBMontageBemerkungProbebetrieb: '',
          SelbstbeteiligungDiebstahl: {
            SBPercentage: 0,
            SBMind: 0,
          },
          sentPriceRequest: false,
        },
      },
      BesondereRisikomerkmale: {
        gebrauchteSachenAlsMontageobjektTK7106: false,
        demontage: '',
        remontage: '',
        remontageNachDemontage: '',
        JHEFurNaturgefahrenGemab: '',
        JHEFurNaturgefahrenGemabEUR: 0,
        neuwertDerGebrauchtenSache: 0,
      },
      Zusatzrisiken: {
        dauer: '',
        monate: '',
        mitversicherungMaintenanceNach: {
          select: 'Nein',
        },
        mitversicherungVonVorlagerplaetzenUndTransportwegen: {
          select: false,
        },
        mitversicherungEntladen: {
          select: false,
        },
        mitversicherungAutokraneNachTK7103: {
          select: false,
          bisZu: 0,
          zuschlag: 0,
        },
        mitversicherungSchwimmendeSachenNachTK7104: {
          select: false,
          bisZu: 0,
          zuschlag: 0,
        },
        eigentumDesMontagepersonalsNachTK7105: {
          select: false,
          bisZu: 0,
          zuschlag: 0,
        },
        mitversicherungArbeitsUndEilfrachtzuschlaegeNachTK7720: {
          select: false,
        },
        mitversicherungInnereUnruhenNachTK7236: {
          select: false,
          bisZu: 0,
        },
        mitversicherungStreikAussperrungNachTK7237: {
          select: false,
        },
        mitversicherungReprasentantenklausel: {
          select: false,
          value: '',
        },
        montageBUVorhanden: {
          select: false,
        },
      },
      Deckungseinschrankungen: {
        ausschlussNaturgefahren: false,
        ausschlussDiebstahl: false,
        ausschlussFlexaTK7210: false,
        ausschlussErdbeben: false,
        ausschlussHerstellungTK7211: '',
        ausschlussGrundwasserGewaesserErdbeben: false,
        ausschlussBestellerinter: '',
        ausschlussHochwasser: false,
        einschraenkungHochwasserTK7260: false,
        einschraenkungHoehereGewaltTK7212: false,
        nachlass: null,
      },
      MitversicherungAufErstesRisiko: {
        luftfrachtkosten: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        zollkosten: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        entsorgungskosten: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        montageausrustung: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        zuschlagFurDieDeckungserweiterungNachTK7209: {
          value: null,
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        fremdeSachenTK7101: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        fremdeSachenErwTK7102: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        baugrundUndBodenmassen: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        bestellerbeistellungen: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        aufraumungskosten: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        bergungskosten: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        arbeitsUndEilfrachtzuschlage: {
          gefahrengrad: null,
          erstrisikosumme: 0,
        },
        andereERSummenLeicht: {
          gefahrengrad: null,
          erstrisikosummeName: '',
          erstrisikosumme: 0,
        },
        andereERSummenSchwer: {
          gefahrengrad: null,
          erstrisikosummeName: '',
          erstrisikosumme: 0,
        },
      },
      accessoriesRisk: {
        sachenImGefahrenbereich: 'Nein',
        erstrisikosumme: 0,
        beitragssatzZuschlag: 0,
        zuschlag: 0,
        selbstbeteiligungPercentage: 0,
        selbstbeteiligungMindEUR: 0,
      },
      notizen: '',
      riskGroupsElements: riskGroups,
      pdfStyles: false,
      queue: [] as string[],
      variantPrice: 'variant1',
      tariffPremiumCalculation: {
        variant1: {
          name: 'SB Variante 1',
        },
        variant2: {
          name: 'SB Variante 2',
        },
        variant3: {
          name: 'SB Variante 3',
        },
      },
      deductibleVariantsCssClasses: {
        variant1: 'table-variant-1',
        variant2: 'table-variant-2',
        variant3: 'table-variant-3',
      },
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'productId', 'id', 'processSavingData', 'insuranceFormsApiData', 'priceData'],
  watch: {
    ...watchers,
    'versicherungssummeGesamt.neuwertKontraktpreisInklFrachtMontageZollkostenGewinn': function () {
      this.versicherungssummeGesamt.gesamtversicherungssumme = this.versicherungssummeGesamt.neuwertKontraktpreisInklFrachtMontageZollkostenGewinn + this.versicherungssummeGesamt.zzglLieferungenUndLeistungenDesBestellers;
    },
    'versicherungssummeGesamt.zzglLieferungenUndLeistungenDesBestellers': function () {
      this.versicherungssummeGesamt.gesamtversicherungssumme = this.versicherungssummeGesamt.neuwertKontraktpreisInklFrachtMontageZollkostenGewinn + this.versicherungssummeGesamt.zzglLieferungenUndLeistungenDesBestellers;
    },
    processSavingData(value: boolean) {
      if (!value) {
        this.executeQueue();
      }
    },
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
  },
  methods: {
    onCurrencyFocus,
    dauerDerMontageGesamtChangeVal(newVal: any) {
      this.montageversicherung.dauerDerMontageGesamtChanged = true;
    },
    dauerDesProbebetriebsGesamtChangeVal(newVal: any) {
      this.montageversicherung.dauerDesProbebetriebsGesamtChanged = true;
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.spreadsheetData) {
        this.ErlauterungZurMontagemabnahme = this.insuranceFormsApiData.spreadsheetData.ErlauterungZurMontagemabnahme;
        recursiveFillData(this.BesondereRisikomerkmale, this.insuranceFormsApiData.spreadsheetData.BesondereRisikomerkmale);
        recursiveFillData(this.Deckungseinschrankungen, this.insuranceFormsApiData.spreadsheetData.Deckungseinschrankungen);
        recursiveFillData(this.MitversicherungAufErstesRisiko, this.insuranceFormsApiData.spreadsheetData.MitversicherungAufErstesRisiko);
        recursiveFillData(this.VertraglicheSelbstbeteiligungen, this.insuranceFormsApiData.spreadsheetData.VertraglicheSelbstbeteiligungen);
        recursiveFillData(this.Zusatzrisiken, this.insuranceFormsApiData.spreadsheetData.Zusatzrisiken);
        recursiveFillData(this.accessoriesRisk, this.insuranceFormsApiData.spreadsheetData.accessoriesRisk);
        recursiveFillData(this.kalkulatorischeRisikenItems, this.insuranceFormsApiData.spreadsheetData.kalkulatorischeRisikenItems);
        recursiveFillData(this.montageversicherung, this.insuranceFormsApiData.spreadsheetData.montageversicherung);
        recursiveFillData(this.versicherungssummeGesamt, this.insuranceFormsApiData.spreadsheetData.versicherungssummeGesamt);
      }
    },
    searchGroup(options: any[], search: string) {
      return options.filter((itm) => itm.group === +search || itm.title.includes(search));
    },
    changedSelbstbeteiligungMontageSBBetrag() {
      this.kalkulatorischeRisikenItems.forEach((item, key) => {
        this.kalkulatorischeRisikenItems[key].kalkulatorischeSelbstbeteiligungMontage = +this.VertraglicheSelbstbeteiligungen.variant3.SelbstbeteiligungMontage.SBBetrag;
      });
    },
    changedSelbstbeteiligungProbebetriebSBBetrag() {
      this.kalkulatorischeRisikenItems.forEach((item, key) => {
        this.kalkulatorischeRisikenItems[key].kalkulatorischeSelbstbeteiligungProbebetrieb = +this.VertraglicheSelbstbeteiligungen.variant3.SelbstbeteiligungProbebetrieb.SBBetrag;
      });
    },
    changedGefahrengrad() {
      if (this.oldGefahrengrad) {
        this.openDialog = true;
      } else {
        this.oldGefahrengrad = this.gefahrengrad;
      }

      this.setGefahrengradValue(this.gefahrengrad);
    },
    onAnwerYesDialog() {
      this.openDialog = false;

      this.setGefahrengradValue(this.gefahrengrad);
      this.oldGefahrengrad = this.gefahrengrad;
    },
    onAnwerNoDialog() {
      this.openDialog = false;

      this.gefahrengrad = this.oldGefahrengrad;
      this.setGefahrengradValue(this.oldGefahrengrad);
    },
    setGefahrengradValue(value: any) {
      Object.keys(this.MitversicherungAufErstesRisiko).forEach((key) => {
        this.MitversicherungAufErstesRisiko[key].gefahrengrad = value;
      });
    },
    executeQueue() {
      // eslint-disable-next-line no-restricted-syntax
      for (const functionName of this.queue) {
        if (typeof this[functionName] === 'function') {
          this[functionName]();
        }
      }
      // Clear the queue after executing all functions
      this.queue = [];
    },
    enqueue(functionName: string) {
      // Add an item to the end of the queue
      this.queue.push(functionName);
    },
    queueGetPriceRequest(variantPrice: string) {
      this.variantPrice = variantPrice;
      this.onSubmit(false, false);
      this.enqueue('getPriceRequest');
    },
    getPriceRequest() {
      apiBackend.getPriceRequestGet(this.id, this.variantPrice).then((response: any) => {
        console.log(response.data);
      }).catch((e) => {
        console.log(`Error - ${e}`);
      });
    },
    queueGetPrice(key: string) {
      this.VertraglicheSelbstbeteiligungen[key].sentPriceRequest = true;

      this.variantPrice = key;
      this.onSubmit(false, false);
      this.enqueue('getPrice');
    },
    getPrice() {
      this.$emit('get-price-use-id', this.id, this.variantPrice);
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Montageversicherung-Tarif-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1620,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    saveZusammenfassungTarifPDF() {
      const element = document.getElementById('element-to-convert');
      const filename = `Zusammenfassung-Montageversicherung-Tarif-${this.id}.pdf`;
      this.pdfStyles = true;
      const opt = {
        margin: 10,
        filename,
        html2canvas: {
          width: 1620,
        },
      };
      html2pdf().set(opt).from(element).output('datauristring')
        .then((response) => {
          const arr = response.split(',');
          const pfdStr = arr[1];
          const data = {
            insuranceId: this.id,
            pfdStr,
          };
          apiBackend.saveTarifPDF(data).then((r) => {
            this.onSubmit();
          });
          this.pdfStyles = false;
        });
    },
    setValues(basicData: any) {
      this.montageversicherung = basicData.montageversicherung;
      this.versicherungssummeGesamt = basicData.versicherungssummeGesamt;
      this.ErlauterungZurMontagemabnahme = basicData.ErlauterungZurMontagemabnahme;
      this.kalkulatorischeRisikenItems = basicData.kalkulatorischeRisikenItems;
      this.VertraglicheSelbstbeteiligungen = basicData.VertraglicheSelbstbeteiligungen;
      this.BesondereRisikomerkmale = basicData.BesondereRisikomerkmale;
      this.Zusatzrisiken = basicData.Zusatzrisiken;
      this.Deckungseinschrankungen = basicData.Deckungseinschrankungen;
      this.MitversicherungAufErstesRisiko = basicData.MitversicherungAufErstesRisiko;
      this.accessoriesRisk = basicData.accessoriesRisk;
      this.notizen = basicData.notizen;
      this.gefahrengrad = basicData?.gefahrengrad;
    },
    onSubmit(nextPage = true, changeTabId = true) {
      const data = {
        montageversicherung: this.montageversicherung,
        versicherungssummeGesamt: this.versicherungssummeGesamt,
        ErlauterungZurMontagemabnahme: this.ErlauterungZurMontagemabnahme,
        kalkulatorischeRisikenItems: this.kalkulatorischeRisikenItems,
        VertraglicheSelbstbeteiligungen: this.VertraglicheSelbstbeteiligungen,
        BesondereRisikomerkmale: this.BesondereRisikomerkmale,
        Zusatzrisiken: this.Zusatzrisiken,
        Deckungseinschrankungen: this.Deckungseinschrankungen,
        MitversicherungAufErstesRisiko: this.MitversicherungAufErstesRisiko,
        accessoriesRisk: this.accessoriesRisk,
        notizen: this.notizen,
        gefahrengrad: this.gefahrengrad,
      };
      this.$emit('spreadsheet-data-change', data, this.key, nextPage, changeTabId);
    },
    initSentPriceRequest() {
      Object.keys(this.VertraglicheSelbstbeteiligungen).forEach((key) => {
        this.VertraglicheSelbstbeteiligungen[key].sentPriceRequest = false;
      });
    },
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    setArtDerIndustriemontageEmpty() {
      this.montageversicherung.artDerIndustriemontage = '';
    },
    setArtDerEnergieMontageEmpty() {
      this.montageversicherung.artDerEnergieMontage = '';
    },
    setPeriodsInMonths(data: any) {
      const diffDatesMontage = differenceBetweenDates(data.montagebeginnVertragsbeginn, data.montageende);

      if (diffDatesMontage.value !== null) {
        this.montageversicherung.dauerDerMontageGesamt.disabledValue = diffDatesMontage.value;
        this.montageversicherung.dauerDerMontageGesamt.enabledValue = diffDatesMontage.period;
      }

      const difference = differenceBetweenDates(data.beginnDerErprobung, data.endeDerErprobung);

      if (difference.value !== null) {
        this.montageversicherung.dauerDesProbebetriebsGesamt.disabledValue = difference.value;
        this.montageversicherung.dauerDesProbebetriebsGesamt.enabledValue = difference.period;
      }
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);

    if (this.insuranceData && this.insuranceData.spreadsheetData) {
      this.setValues(this.insuranceData.spreadsheetData);
    } else {
      this.fillApiValuesData();
    }

    this.initSentPriceRequest();

    if (this.insuranceData && this.insuranceData.basicData) {
      this.setPeriodsInMonths(this.insuranceData.basicData);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (this.isPCUser) return;
    this.onSubmit(false, false);
  },
  computed: {
    showKalkulatorischeSelbstbeteiligungMontage(): boolean {
      return +this.VertraglicheSelbstbeteiligungen.variant3.SelbstbeteiligungMontage.SBBetrag > 0;
    },
    showKalkulatorischeSelbstbeteiligungProbebetrieb(): boolean {
      return +this.VertraglicheSelbstbeteiligungen.variant3.SelbstbeteiligungProbebetrieb.SBBetrag > 0;
    },
    showArtDerIndustriemontage(): boolean {
      return !this.montageversicherung.artDerEnergieMontage;
    },
    showArtDerEnergieMontage(): boolean {
      return !this.montageversicherung.artDerIndustriemontage;
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
});
